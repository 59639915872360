@import "../../../style-variables.scss";

.AdvancedFiltersSection {
  border-radius: 4px;
  width: fit-content;

  &.hasError {
    border-style: solid;
    border-color: $danger;
    border-width: 1px;
  }
}
