.cell-completed {
  background-color: #A1CCCE;
}

.cell-running {
  background-color: #efd75a;
}

.cell-not-started {
  background-color: #ebebeb;
}

.cell-paused {
  background-color: #cbd9e4;
}

.form-switch .form-check-input {
  cursor: pointer;
  margin-right: 15px;
}

.myform-check-input > .form-check-input:focus {
  box-shadow: none;
}

.myform-check-input > .form-check-input:focus:not(:checked) {
  filter: saturate(0%);
}

.myform-check-input > .form-check-input:checked {
  background-color: #e20074;
  border-color: #e20074;
}

.ag-row {
  overflow: hidden;
}

.mtlrSetButton {
  width: 171px;
  white-space: nowrap;
}

.bulkActionButton {
  font-size: 1em;
  padding: 0.175rem 0.55rem;
}

.Mtrl-tooltip .tooltip-inner {
  max-width: 100% !important;
  text-align: left;
}
