/* remove the default react-datepicker close button styling */
.datepicker-close-button::after {
  content: none;
}

/* if a day is selected that is not today, highlight today in yellow */
.react-datepicker__day--today {
  border-radius: .3rem;
  background: #ffff76;
  color: black;
}

.react-datepicker__day--selected.react-datepicker__day--today,
.react-datepicker__day--keyboard-selected.react-datepicker__day--today {
  border-radius: .3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__input-time-container {
  border-top: 1px solid grey;
  margin-left: 0;
  padding: 10px 10px 0px 10px;
}

.react-datepicker-time__caption {
  font-weight: bold;
}

