/* change the background color of the active tab */
#navContainer a[role="tab"].nav-link.active {
  background-color: white;
  color: black
}

/* need the left border of the first tab when active to have a white border */
  #navContainer a[role="tab"].nav-link.active:first-child {
    border-color: white white;
  }

/* inactive tab styling*/
#navContainer a[role="tab"].nav-link {
  border-top-color: white;
  border-right-color: white;
  border-left-color: white;
  background-color: #ebebeb;
  color: black;
  padding: 0.3rem 1rem;
}
