@import "../../../style-variables.scss";

.BatchTabs {
  & div.BatchTabNav {
    border: 0;
    border-left: 1px;
    border-top: 1px;
    border-bottom: 1px;
    border-style: solid;
    border-color: #dfdfdf;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    overflow: hidden;

    &.hasError {
      color: $danger;
      background-color: $danger;
      border-top-color: $danger;
      border-left-color: $danger;
      border-bottom-color: $danger;
      border-top-width: medium;
      border-left-width: medium;
      border-bottom-width: medium;
    }

    & div.BatchTab {
      background-color: #ebebeb;
      padding: .5rem;
      width: 100%;
      display: flex;
      justify-content: space-between;

      &:hover {
        background-color: #b8b8b8;
        font-weight: bold;
      }

      &.active {
        background-color: white;
        font-weight: bolder;
        position: relative;
        z-index: 99;
      }

      & a[role="tab"].nav-link.active {
        background-color: white;
      }

      & a[role="tab"].nav-link:not(.active) {
        &:hover {
         cursor: pointer;
        }

      }
    }
  }
}
