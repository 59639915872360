#addModal > .modal-content {
  width: 147%
}

/* positions the error icon */
.react-datepicker__input-container > .startDate.form-control.is-invalid {
  background-position: right calc(1.375em + .1875rem) center;
}

/* ensure datepicker popper is on the top of msisdn*/
#addModal > .modal-content .react-datepicker-popper {
  z-index: 2;
}
