.TooltipIcon {
  cursor: pointer;
  margin-left: 0.3rem;
}

.TooltipOverlay {
  position: fixed;
}

.tooltip-inner {
  max-width: 70%;
}