.lcat-form-switch > .form-check-input:focus {
  box-shadow: none;
}

.lcat-form-switch > .form-check-input:focus:not(:checked) {
  filter: saturate(0%);
}

.lcat-form-switch > .form-check-input:checked {
  background-color: #e20074;
  border-color: #e20074;
}
