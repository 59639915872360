
/* change the background color of the active tab */
.ground-truth-nav a[role="tab"].nav-link.active {
  background-color: lightgray;
  color: black
}

.ground-truth-nav a[role="tab"].nav-link {
  background-color: white;
  padding: 0.3rem 1rem;
  color: black;
  padding: 0.3rem 1rem;
}

.ground-truth-row {
  border-style: solid;
  border-width: thin;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.ground-truth-col-nav {
  padding: 0 !important;
}

.ground-truth-col-tab {
  border: 0;
  border-left: 1px;
  border-style: solid;
  border-color: #dfdfdf;
  padding-top: 5px
}

/* positions the error icon */
.react-datepicker__input-container > .customEndDate.form-control.is-invalid {
  background-position: right calc(1.375em + .1875rem) center;
}
