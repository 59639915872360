$primary: #09417a;
$secondary: #6290c8;
$success: #57a56e;
$warning: #efb95a;
$danger: #ca5e47;
$white: #fefefe;
$off-white: #f9f9f9;
$gray: #dae0e5;

$input-placeholder-color: #adb5bd;

$enable-validation-icons: false;

$btn-disabled-opacity: 0.25;

$gray-700: #495057 !default;
$gray-900: #212529 !default;
$dark: $gray-900 !default;

